import logo from './logo.svg';
import './App.css';
import Retool from "react-retool";
import React, { useState } from 'react';


function App() {
  const [show, setShow] = useState(true);

  return (
    <div className="App">
        {show && 
          <div id="login-help">        
            <h1>Login Trouble?</h1>
            <p>Login here: <a href="https://backabl.retool.com">https://backabl.retool.com</a></p>
            <p>Then return to this page</p>
            <button onClick={() => setShow(prev => !prev)}>Hide</button>
          </div>
        }

      <div className="retool-box">
          <Retool url="https://backabl.retool.com/apps/Homepage" />
      </div>
    </div>
  );
}

export default App;
